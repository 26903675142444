sample_container.card_sample {
    max-width: 300px;
    margin: auto;
    min-height: 400px;
}

/* Card Customizations */

.sample_container.card_sample .e-custom-card {
    position: relative;
    margin-top: 80px;
    overflow: visible;
    border-radius: 15px;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    transition: 0.2s;
    border-width: 1px;
    padding: 1px;
}

body.bootstrap-dark .e-custom-card .avatar-content {
    color: #ffffff;
}

.tailwind .e-custom-card .e-avatar,
body.tailwind-dark .e-custom-card .e-avatar,
.bootstrap5 .e-custom-card .e-avatar,
.bootstrap5-dark .e-custom-card .e-avatar,
.fluent .e-custom-card .e-avatar,
.fluent-dark .e-custom-card .e-avatar {
    height: 3em;
    width: 3em;
}

.e-custom-card.e-card:hover {
    border-width: 1px;
    padding: 1px;
}

.e-custom-card :nth-child(2) .e-card-header-title.name {
    margin-top: 20px;
}

.sample_container p.avatar-content {
    line-height: 20px;
    font-family: inherit;
}

.sample_container.card_sample .e-custom-card .e-card-header {
    text-align: center;
}

.sample_container.card_sample .e-custom-card .e-avatar {
    font-size: 40px;
    position: absolute;
    top: calc(0% - 1.5em);
    left: calc(50% - 1.5em);
    box-shadow: 0 16px 28px -8px rgba(0, 0, 0, .36), 0 4px 15px 0 rgba(0, 0, 0, .12), 0 8px 10px -5px rgba(0, 0, 0, .2);
}

.sample_container.card_sample .e-card.e-custom-card :nth-child(3) {
    padding: 12px 0px 20px 0px;
    height: 130px;
}

.sample_container.card_sample .e-custom-card.e-card .e-card-header .e-card-header-caption .e-card-header-title {
    font-size: 24px;
    font-weight: 500;
    line-height: normal;
    color: rgba(83, 101, 132, 0.65);
    text-shadow: 0 0 0.1px;
}

.sample_container.card_sample .e-custom-card.e-card .e-card-header .e-card-header-caption .e-card-sub-title {
    font-size: 12px;
    font-weight: 500;
    line-height: normal;
    color: rgba(86, 90, 97, 0.65);
    text-shadow: 0 0 0.1px;
}

.sample_container.card_sample .e-custom-card.e-card .e-card-content {
    overflow: visible;
    width: auto;
    margin: -5px 20px 0 20px;
    word-spacing: 1px;
}

.sample_container.card_sample .avatar-content {
    color: rgba(250, 200, 130, 0.6);
    margin: 0 auto;
    text-align: center;
    color: rgb(94, 94, 94);
    border: none;
    padding: 0;
    font-size: 14px;
}

.avatar-content {
    margin-bottom: 0;
}

.sample_container.card_sample .sample_container .name {
    margin-top: 10px;
}

.highcontrast .e-custom-card.e-card .avatar-content,
.bootstrap-dark .e-custom-card.e-card .avatar-content,
.tailwind-dark .e-custom-card.e-card .avatar-content,
.material-dark .e-custom-card.e-card .avatar-content,
.fabric-dark .e-custom-card.e-card .avatar-content,
.bootstrap5-dark .e-custom-card.e-card .avatar-content {
    color: rgba(255, 255, 255, 0.84);
}

.highcontrast .sample_container.card_sample .e-custom-card.e-card .e-card-header .e-card-header-caption .e-card-sub-title,
.bootstrap-dark .sample_container.card_sample .e-custom-card.e-card .e-card-header .e-card-header-caption .e-card-sub-title,
.tailwind-dark .sample_container.card_sample .e-custom-card.e-card .e-card-header .e-card-header-caption .e-card-sub-title,
.material-dark .sample_container.card_sample  .e-custom-card.e-card .e-card-header .e-card-header-caption .e-card-sub-title,
.fabric-dark .sample_container.card_sample  .e-custom-card.e-card .e-card-header .e-card-header-caption .e-card-sub-title,
.bootstrap5-dark .sample_container.card_sample  .e-custom-card.e-card .e-card-header .e-card-header-caption .e-card-sub-title {
    color: rgba(255, 255, 255, 0.45);
}

.highcontrast .sample_container.card_sample .e-custom-card.e-card .e-card-header .e-card-header-caption .e-card-header-title,
.bootstrap-dark .sample_container.card_sample .e-custom-card.e-card .e-card-header .e-card-header-caption .e-card-header-title,
.tailwind-dark .sample_container.card_sample .e-custom-card.e-card .e-card-header .e-card-header-caption .e-card-header-title,
.material-dark .sample_container.card_sample .e-custom-card.e-card .e-card-header .e-card-header-caption .e-card-header-title,
.fabric-dark .sample_container.card_sample .e-custom-card.e-card .e-card-header .e-card-header-caption .e-card-header-title,
.bootstrap5-dark .sample_container.card_sample .e-custom-card.e-card .e-card-header .e-card-header-caption .e-card-header-title {
    color: rgba(255, 255, 255, 0.84);
}